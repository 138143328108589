import axios from 'axios';

export default class ApplicationResource {
  myApplications() {
    return axios.get('/api/applications');
  }

  applicationTemplates() {
    return axios.get('/api/applications/templates');
  }

  applicationTemplate(id) {
    return axios.get('/api/applications/templates/'+id);
  }

  getApplication(id) {
    return axios.get('/api/applications/'+id);
  }

  loadInputData(templateId, inputId, subjectId) {
    return axios.get('/api/applications/templates/'+templateId+'/'+inputId+'?subject_id='+subjectId);
  }

  writeApplication(formData) {


 

    return axios({
      url: '/api/applications',
      method: 'POST',
      data: formData,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      }
    });
  }


  confirmApplication(string){

      return axios.post('api/confirmation',{string})


  }


  downloadPDF(correspondenceID){
 

    return axios.post('/api/applications/download',{correspondenceID},{responseType:'blob'})
 
  

  }


  getApplicationProgress(correspondenceID){

      return axios.get('/api/applications/getApplicationProgress/'+correspondenceID)

  }




}
